const CouponMockData = {};
CouponMockData.fetchAllCoupons = {
  data: [
    {
      status: true,
      created_at: "2024-12-02T17:52:07.160145",
      updated_at: "2024-12-02T17:52:07.162218",
      name: "Holiday Special",
      discount_percentage: 20,
      start_date: "2024-12-15",
      end_date: "2024-12-31",
      max_uses: 100,
      coupon_code: "HOLIDAY20",
      min_purchase_amount: 50,
      id: 1,
    },
    {
      status: true,
      created_at: "2024-11-25T10:30:00.000000",
      updated_at: "2024-11-30T12:00:00.000000",
      name: "Black Friday Deal",
      discount_percentage: 50,
      start_date: "2024-11-29",
      end_date: "2024-12-01",
      max_uses: 500,
      coupon_code: "BLACKFRIDAY50",
      min_purchase_amount: 20,
      id: 2,
    },
    {
      status: false,
      created_at: "2024-10-10T09:15:45.123456",
      updated_at: "2024-10-15T11:30:00.987654",
      name: "October Fest",
      discount_percentage: 10,
      start_date: "2024-10-15",
      end_date: "2024-10-31",
      max_uses: 200,
      coupon_code: "OCTFEST10",
      min_purchase_amount: 30,
      id: 3,
    },
    {
      status: true,
      created_at: "2024-09-20T14:22:35.543210",
      updated_at: "2024-09-21T10:00:00.000000",
      name: "Summer Bonanza",
      discount_percentage: 15,
      start_date: "2024-09-25",
      end_date: "2024-10-05",
      max_uses: 300,
      coupon_code: "SUMMER15",
      min_purchase_amount: 40,
      id: 4,
    },
    {
      status: true,
      created_at: "2024-08-15T12:00:00.000000",
      updated_at: "2024-08-16T13:30:00.000000",
      name: "Independence Sale",
      discount_percentage: 25,
      start_date: "2024-08-15",
      end_date: "2024-08-20",
      max_uses: 150,
      coupon_code: "FREEDOM25",
      min_purchase_amount: 60,
      id: 5,
    },
    {
      status: true,
      created_at: "2024-07-05T11:45:23.987654",
      updated_at: "2024-07-06T10:00:00.123456",
      name: "Mid-Year Sale",
      discount_percentage: 30,
      start_date: "2024-07-10",
      end_date: "2024-07-15",
      max_uses: 250,
      coupon_code: "MIDYEAR30",
      min_purchase_amount: 70,
      id: 6,
    },
    {
      status: false,
      created_at: "2024-06-20T08:30:00.000000",
      updated_at: "2024-06-25T09:00:00.000000",
      name: "Summer Clearance",
      discount_percentage: 40,
      start_date: "2024-06-25",
      end_date: "2024-06-30",
      max_uses: 400,
      coupon_code: "CLEAR40",
      min_purchase_amount: 100,
      id: 7,
    },
    {
      status: true,
      created_at: "2024-05-10T14:15:00.000000",
      updated_at: "2024-05-12T16:30:00.000000",
      name: "Mother's Day Special",
      discount_percentage: 20,
      start_date: "2024-05-10",
      end_date: "2024-05-15",
      max_uses: 100,
      coupon_code: "MOM20",
      min_purchase_amount: 30,
      id: 8,
    },
    {
      status: false,
      created_at: "2024-04-01T10:00:00.000000",
      updated_at: "2024-04-02T11:30:00.000000",
      name: "April Fools' Discount",
      discount_percentage: 10,
      start_date: "2024-04-01",
      end_date: "2024-04-01",
      max_uses: 50,
      coupon_code: "FOOL10",
      min_purchase_amount: 20,
      id: 9,
    },
    {
      status: true,
      created_at: "2024-03-17T13:45:00.000000",
      updated_at: "2024-03-18T14:15:00.000000",
      name: "St. Patrick's Day Deal",
      discount_percentage: 17,
      start_date: "2024-03-17",
      end_date: "2024-03-20",
      max_uses: 200,
      coupon_code: "LUCKY17",
      min_purchase_amount: 40,
      id: 10,
    },
    {
      status: true,
      created_at: "2024-02-14T09:00:00.000000",
      updated_at: "2024-02-14T18:00:00.000000",
      name: "Valentine's Day Special",
      discount_percentage: 14,
      start_date: "2024-02-14",
      end_date: "2024-02-14",
      max_uses: 100,
      coupon_code: "LOVE14",
      min_purchase_amount: 25,
      id: 11,
    },
    {
      status: false,
      created_at: "2024-01-01T00:00:00.000000",
      updated_at: "2024-01-01T23:59:59.000000",
      name: "New Year Offer",
      discount_percentage: 25,
      start_date: "2024-01-01",
      end_date: "2024-01-01",
      max_uses: 500,
      coupon_code: "NEWYEAR25",
      min_purchase_amount: 50,
      id: 12,
    },
    {
      status: true,
      created_at: "2023-12-25T10:00:00.000000",
      updated_at: "2023-12-26T10:00:00.000000",
      name: "Christmas Special",
      discount_percentage: 30,
      start_date: "2023-12-25",
      end_date: "2023-12-31",
      max_uses: 300,
      coupon_code: "XMAS30",
      min_purchase_amount: 75,
      id: 13,
    },
    {
      status: false,
      created_at: "2023-11-11T11:11:11.111111",
      updated_at: "2023-11-12T12:12:12.121212",
      name: "Singles' Day Offer",
      discount_percentage: 11,
      start_date: "2023-11-11",
      end_date: "2023-11-12",
      max_uses: 111,
      coupon_code: "SINGLE11",
      min_purchase_amount: 20,
      id: 14,
    },
    {
      status: true,
      created_at: "2023-10-31T23:59:59.000000",
      updated_at: "2023-11-01T00:00:00.000000",
      name: "Halloween Sale",
      discount_percentage: 25,
      start_date: "2023-10-31",
      end_date: "2023-11-05",
      max_uses: 250,
      coupon_code: "HALLOWEEN25",
      min_purchase_amount: 50,
      id: 15,
    },
  ],
  status: {
    message: "success",
    status_code: 200,
  },
};

export default CouponMockData;
