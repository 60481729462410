const LocationMockData = {};
LocationMockData.getAllVenues = {
  data: [
    {
      status: true,
      created_at: "2024-12-02T15:14:37.870101",
      updated_at: "2024-12-02T15:14:37.871099",
      name: "calicut",
      capacity: 100,
      indoor: true,
      address: "ABC street",
      latitude: null,
      longitude: null,
      id: 1,
    },
    {
      status: true,
      created_at: "2024-12-04T06:52:39",
      updated_at: "2024-12-04T06:52:39",
      name: "New2",
      capacity: 122,
      indoor: false,
      address: "abc",
      latitude: null,
      longitude: null,
      id: 2,
    },
    {
      status: true,
      created_at: "2024-12-06T10:46:01.034266",
      updated_at: "2024-12-06T10:46:01.034266",
      name: "kdajfs",
      capacity: 0,
      indoor: true,
      address: "string",
      latitude: null,
      longitude: null,
      id: 3,
    },
    {
      status: true,
      created_at: "2024-12-06T11:14:24.414881",
      updated_at: "2024-12-06T11:14:24.414881",
      name: "kdajfs",
      capacity: 0,
      indoor: true,
      address: "string",
      latitude: null,
      longitude: null,
      id: 4,
    },
    {
      status: true,
      created_at: "2024-12-06T11:35:03.014144",
      updated_at: "2024-12-06T11:35:03.015175",
      name: "ojjdk",
      capacity: 0,
      indoor: true,
      address: "string",
      latitude: null,
      longitude: null,
      id: 7,
    },
    {
      status: true,
      created_at: "2024-12-06T11:35:03.014144",
      updated_at: "2024-12-06T11:35:03.015175",
      name: "town hall",
      capacity: 0,
      indoor: true,
      address: "string",
      latitude: null,
      longitude: null,
      id: 8,
    },
    {
      status: true,
      created_at: "2024-12-06T14:47:17.095290",
      updated_at: "2024-12-06T14:47:17.095290",
      name: "km",
      capacity: 0,
      indoor: false,
      address: "hijkl",
      latitude: 23.44326020333297,
      longitude: 53.84798206604145,
      id: 9,
    },
  ],
  status: {
    message: "success",
    status_code: 200,
  },
};
LocationMockData.fetchAllCountries = {
  data: [
    {
      id: 1,
      country: "United States",
      code: "US",
      timezone: "UTC-5 to UTC-10",
    },
    { id: 2, country: "United Kingdom", code: "GB", timezone: "UTC+0" },
    { id: 3, country: "Canada", code: "CA", timezone: "UTC-3.5 to UTC-8" },
    { id: 4, country: "China", code: "CN", timezone: "UTC+8" },
    { id: 5, country: "India", code: "IN", timezone: "UTC+5:30" },
    { id: 6, country: "Australia", code: "AU", timezone: "UTC+8 to UTC+11" },
    { id: 7, country: "Germany", code: "DE", timezone: "UTC+1" },
    { id: 8, country: "France", code: "FR", timezone: "UTC+1" },
    { id: 9, country: "Japan", code: "JP", timezone: "UTC+9" },
    { id: 10, country: "Russia", code: "RU", timezone: "UTC+2 to UTC+12" },
    { id: 11, country: "Brazil", code: "BR", timezone: "UTC-2 to UTC-5" },
    { id: 12, country: "South Africa", code: "ZA", timezone: "UTC+2" },
    { id: 13, country: "Italy", code: "IT", timezone: "UTC+1" },
    { id: 14, country: "Spain", code: "ES", timezone: "UTC+1" },
    { id: 15, country: "Mexico", code: "MX", timezone: "UTC-5 to UTC-8" },
    { id: 16, country: "South Korea", code: "KR", timezone: "UTC+9" },
    { id: 17, country: "Saudi Arabia", code: "SA", timezone: "UTC+3" },
    { id: 18, country: "Turkey", code: "TR", timezone: "UTC+3" },
    { id: 19, country: "Argentina", code: "AR", timezone: "UTC-3" },
    { id: 20, country: "Indonesia", code: "ID", timezone: "UTC+7 to UTC+9" },
    { id: 21, country: "United Arab Emirates", code: "AE", timezone: "UTC+4" },
  ],
  status: {
    message: "success",
    status_code: 200,
  },
};
export default LocationMockData;
