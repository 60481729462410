const OfferMockData = {};
OfferMockData.fetchAllOffers = {
  data: [
    {
      status: true,
      created_at: "2024-12-02T17:52:07.090430",
      updated_at: "2024-12-02T17:52:07.091016",
      name: "Holiday Discount",
      discount_percentage: 25,
      start_date: "2024-12-05",
      end_date: "2024-12-25",
      max_uses: 100,
      id: 1,
    },
    {
      status: true,
      created_at: "2024-12-02T17:52:07.090430",
      updated_at: "2024-12-02T17:52:07.091016",
      name: "Black Friday Deal",
      discount_percentage: 50,
      start_date: "2024-11-29",
      end_date: "2024-11-30",
      max_uses: 200,
      id: 2,
    },
    {
      status: true,
      created_at: "2024-12-02T17:52:07.090430",
      updated_at: "2024-12-02T17:52:07.091016",
      name: "New Year Special",
      discount_percentage: 20,
      start_date: "2024-12-31",
      end_date: "2025-01-01",
      max_uses: 150,
      id: 3,
    },
    {
      status: false,
      created_at: "2024-12-02T17:52:07.090430",
      updated_at: "2024-12-02T17:52:07.091016",
      name: "Summer Flash Sale",
      discount_percentage: 10,
      start_date: "2024-06-15",
      end_date: "2024-06-20",
      max_uses: 50,
      id: 4,
    },
    {
      status: true,
      created_at: "2024-12-02T17:52:07.090430",
      updated_at: "2024-12-02T17:52:07.091016",
      name: "Cyber Monday Discount",
      discount_percentage: 30,
      start_date: "2024-12-02",
      end_date: "2024-12-02",
      max_uses: 300,
      id: 5,
    },
  ],
  status: {
    message: "success",
    status_code: 200,
  },
};
export default OfferMockData;
